<template>
  <div>
    <button @click="openPopup">Create Assignment</button>
    <div v-if="showPopup" class="popup-overlay" @click="closePopup"></div>
    <div v-if="showPopup" class="popup-window">
      <p class="popup-heading">New Assignment</p>

      <!-- Sliding button -->
      <div class="sliding-button">
        <div class="options">
          <div
            class="option"
            :class="{ active: assignmentType === 'scratch' }"
            @click="selectOption('scratch')"
          >
            Create from Scratch
          </div>
          <div
            class="option"
            :class="{ active: assignmentType === 'document' }"
            @click="selectOption('document')"
          >
            Create from Document
          </div>
        </div>
        <div class="slider" :style="sliderStyle"></div>
      </div>

      <!-- Create from scratch -->
      <div v-if="assignmentType === 'scratch'" class="scratch-form">
        <div class="step-indicators">
          <div
            v-for="step in 4"
            :key="step"
            :class="['step-indicator', { active: currentStep === step - 1 }]"
          ></div>
        </div>
        <div class="sliding-menu-container">
          <div
            class="sliding-menu"
            :style="{ transform: `translateX(-${currentStep * 25}%)` }"
          >
            <!-- Step 1: Title and Description -->
            <div class="step">
              <div class="form-group">
                <p class="step-field">What is the title of the assignment?</p>
                <input
                  type="text"
                  v-model="title"
                  id="title"
                  required
                  placeholder="Assignment Title"
                />
              </div>
              <div class="form-group">
                <p class="step-field">Provide a description (optional)</p>
                <textarea
                  v-model="description"
                  id="description"
                  placeholder="Description"
                  rows="4"
                ></textarea>
              </div>
              <button @click="nextStep" class="next-btn">
                Next
                <span class="material-symbols-outlined">arrow_forward</span>
              </button>
            </div>

            <!-- Step 2: Due Date -->
            <div class="step">
              <div class="form-group">
                <p class="step-field">
                  What is the due date for the assignment?
                </p>
                <input type="date" v-model="dueDate" id="dueDate" required />
              </div>
              <div class="nav-buttons">
                <button @click="prevStep" class="prev-btn">
                  <span class="material-symbols-outlined">arrow_back</span>
                  Previous
                </button>
                <button @click="nextStep" class="next-btn">
                  Next
                  <span class="material-symbols-outlined">arrow_forward</span>
                </button>
              </div>
            </div>

            <!-- Step 3: Questions -->
            <div class="step step-overflow">
              <p class="step-heading">Add Questions</p>
              <div
                v-for="(question, index) in questions"
                :key="index"
                class="question-item"
              >
                <div class="question-row">
                  <div>
                    <span>Question {{ index + 1 }}</span
                    ><span
                      class="material-symbols-outlined remove-icon"
                      @click="removeQuestion(index)"
                      >delete</span
                    >
                  </div>
                  <div>
                    Marks:
                    <input
                      type="number"
                      v-model="question.marks"
                      class="marks-picker"
                    />
                  </div>
                </div>
                <TeacherTiptap
                  v-model:content="question.content"
                  :editable="true"
                />
              </div>
              <button @click="addQuestion" class="add-btn">
                <span class="material-symbols-outlined">add</span>
              </button>
              <div class="nav-buttons">
                <button @click="prevStep" class="prev-btn">
                  <span class="material-symbols-outlined">arrow_back</span>
                  Previous
                </button>
                <button @click="previewAssignment" class="create-btn">
                  Preview Assignment
                </button>
              </div>
            </div>

            <!-- Step 4: Preview -->
            <div class="step" v-if="currentStep === 3">
              <p class="step-header">Preview</p>
              <div class="preview-content">
                <h2>{{ title }}</h2>
                <p><strong>Description:</strong> {{ description }}</p>
                <p><strong>Due Date:</strong> {{ formatDate(dueDate) }}</p>
                <div
                  v-for="(question, index) in questions"
                  :key="index"
                  class="preview-question"
                >
                  <div class="question-title">
                    <div class="question-number">Question {{ index + 1 }}</div>
                    <div class="question-content">
                      <TiptapPreview :content="question.content" />
                    </div>
                  </div>
                  <div class="grade">--/{{ question.marks }}</div>
                </div>
              </div>
              <div class="nav-buttons">
                <button @click="prevStep" class="prev-btn">
                  <span class="material-symbols-outlined">arrow_back</span>
                  Previous
                </button>
                <button @click="createAssignment" class="create-btn">
                  Create Assignment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Create from document -->
      <div v-else>
        <div
          class="custom-file-input"
          id="drop-zone"
          @dragover.prevent="handleDragOver"
          @dragleave.prevent="handleDragLeave"
          @drop.prevent="handleDrop"
          v-if="!fileUrl"
        >
          <input
            ref="fileInput"
            type="file"
            class="file-input"
            accept="application/pdf,image/jpeg,image/png"
            @change="handleFileSelect"
          />

          <span
            class="material-symbols-outlined upload-button"
            @click="triggerFileInput"
          >
            add_circle
          </span>

          <p class="drag-drop-text">
            Drag and drop or click to upload <br />
            (pdf/jpeg/png)
          </p>
        </div>

        <div v-if="loading" class="spinner">Loading...</div>

        <div class="document-container" v-if="fileUrl && !loading">
          <iframe
            v-if="isPDF"
            :src="fileUrl"
            type="application/pdf"
            class="pdf-display"
          ></iframe>
          <img
            v-else
            :src="fileUrl"
            alt="Uploaded file"
            class="image-display"
          />
          <div class="display-buttons">
            <button class="remove-button" @click="removeFile">
              Remove File
            </button>
            <button class="continue-button">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeacherTiptap from "./TeacherTiptap.vue";
import TiptapPreview from "./TiptapPreview.vue";
export default {
  components: {
    TeacherTiptap,
    TiptapPreview,
  },
  data() {
    return {
      showPopup: true,
      assignmentType: "scratch",
      title: "",
      description: "",
      dueDate: "",
      loading: false,
      fileUrl: null,
      isPDF: false, // To differentiate between PDF and image
      currentStep: 0,
      questions: [
        {
          content: null,
          marks: 0,
        },
      ],
    };
  },
  computed: {
    sliderStyle() {
      return {
        transform:
          this.assignmentType === "scratch"
            ? "translateX(0%)"
            : "translateX(100%)",
        width: "50%",
      };
    },
  },
  methods: {
    openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      this.resetForm();
    },
    selectOption(option) {
      this.assignmentType = option;
    },
    nextStep() {
      // Validate before advancing to the next step
      if (this.currentStep === 0 && !this.title) {
        alert("Title is required.");
        return;
      } else if (this.currentStep === 1 && !this.dueDate) {
        alert("Due Date is required.");
        return;
      }

      if (this.currentStep < 3) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },
    addQuestion() {
      this.questions.push({
        content: "",
        marks: 0,
      });
    },
    removeQuestion(index) {
      this.questions.splice(index, 1);
    },
    previewAssignment() {
      console.log(this.questions);
      // Navigate to the preview step
      this.currentStep++;
    },
    createAssignment() {
      const newAssignment = {
        title: this.title,
        description: this.description,
        dueDate: this.dueDate,
        questions: this.questions,
      };
      this.$emit("create-assignment", newAssignment);
      // this.closePopup();
    },
    resetForm() {
      this.title = "";
      this.description = "";
      this.dueDate = "";
      this.fileUrl = null;
      this.isPDF = false;
      this.currentStep = 0;
      this.questions = [];
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.loadFile(file);
    },
    handleDragOver(event) {
      document.getElementById("drop-zone").classList.add("drag-over");
    },
    handleDragLeave(event) {
      document.getElementById("drop-zone").classList.remove("drag-over");
    },
    handleDrop(event) {
      document.getElementById("drop-zone").classList.remove("drag-over");
      const file = event.dataTransfer.files[0];
      if (file) {
        this.loadFile(file);
      }
    },
    loadFile(file) {
      this.loading = true;
      this.isPDF = file.type === "application/pdf";
      const url = URL.createObjectURL(file);
      this.fileUrl = url;
      this.loading = false;
    },
    removeFile() {
      URL.revokeObjectURL(this.fileUrl);
      this.resetForm();
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup-window {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--accent3);
  padding: 1vw;
  box-shadow: var(--box-shadow);
  z-index: 1001;
  border-radius: 1rem;
  overflow: auto;
}

.popup-heading {
  width: 100%;
  text-align: center;
  font-size: 1.4vw;
  /* padding-top: 1vw; */
}

.sliding-button {
  position: relative;
  width: 50%;
  height: 5vh;
  margin: 2vh auto;
  background-color: var(--background-color);
  border-radius: 2rem;
  /* overflow: hidden; */
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.options {
  display: flex;
  height: 5vh;
}

.option {
  flex: 1;
  display: flex;
  height: 5vh;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-size: 0.8vw;
  transition: color 0.3s;
  z-index: 2;
}

.option.active {
  color: #fff;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: var(--classi2);
  border-radius: 2rem;
  transition: transform 0.3s;
  z-index: 1;
}

.scratch-form {
  padding: 2vw;
}

.sliding-menu-container {
  overflow-x: hidden;
}

.sliding-menu {
  display: flex;
  height: fit-content;
  transition: transform 0.3s ease;
  width: 400%;
}

.step {
  width: 25%;
  height: 53vh;
  padding: 2vw;
  padding-bottom: 0;
  overflow: auto;
}

.step-heading {
  font-size: 1.7vw;
}

.step-field {
  padding-bottom: 1vw;
  font-size: 1.5vw;
}

.form-group {
  margin-bottom: 2vw;
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 1.5vw;
  border-radius: 1rem;
  border: none;
  font-size: 1vw;
  transition: border-color 0.3s ease;
}

.form-group input[type="text"]:focus,
.form-group textarea:focus {
  border-color: var(--classi2);
  outline: none;
}

input[type="date"] {
  width: 100%;
  padding: 1.5vw;
  border-radius: 1rem;
  border: none;
  font-size: 0.9vw;
}

.nav-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2vw;
}

button:hover {
  transition: 0.3 all ease;
  opacity: 0.6;
}

.next-btn,
.prev-btn,
.create-btn {
  padding: 1vw 2vw;
  float: right;
  border: none;
  border-radius: 1rem;
  font-size: 1vw;
  cursor: pointer;
  display: flex; /* Add flexbox */
  align-items: center; /* Vertically align items */
  justify-content: center; /* Horizontally center items */
  gap: 0.5vw; /* Space between text and arrow */
}

.next-btn,
.create-btn {
  background-color: var(--classi2);
  color: white;
}

.prev-btn {
  background-color: #f0f0f0;
  color: #333;
}

.add-btn {
  display: flex;
  border: none;
  border-radius: 50%;
  background-color: var(--primary);
  font-size: 1.3vw;
  color: var(--secondary);
  padding: 1vw;
  margin: auto;
}

.remove-btn {
  background-color: #ff4d4d;
  color: white;
  margin-top: 1.5vw;
}

.question-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3vw;
}

.marks-picker {
  width: 10vh;
  background-color: var(--primary);
  border: none;
  border-radius: 1vw;
  padding: 0.3vw;
  font-size: 1vw;
  text-align: center;
}

.remove-icon {
  cursor: pointer;
  padding-left: 1vw;
  font-size: 1vw;
  color: var(--primary);
}

.remove-icon:hover {
  opacity: 0.6;
}

.question-item {
  margin: 2vw 0vw;
  border-radius: 0.5vw;
}

.step-indicators {
  display: flex;
  justify-content: center;
}

.step-indicator {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 0.5vw;
}

.step-indicator.active {
  background-color: var(--classi2);
}

.custom-file-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vw;
  height: 63vh;
  border: 0.1vw dashed var(--accent1);
  border-radius: 1rem;
  text-align: center;
  transition: background-color 0.3s ease;
}

.file-input {
  display: none;
}

.upload-button {
  color: var(--primary);
  cursor: pointer;
  font-size: 2vw;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  opacity: 0.6;
}

.upload-button:active {
  color: var(--classi2);
}

.custom-file-input.drag-over {
  background-color: #e3f2fd;
  border-color: #2196f3;
}

.drag-drop-text {
  margin-top: 1vh;
  color: #777;
  font-size: 0.7vw;
  line-height: 2;
}

.spinner {
  text-align: center;
  font-size: 1.2vw;
  margin-top: 2vw;
}

.document-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-display {
  height: 55vh;
  width: auto;
  object-fit: contain;
}

.pdf-display {
  margin: auto;
  height: 55vh;
  width: 40vw;
}

.display-buttons {
  display: flex;
  justify-content: space-evenly;
}

.remove-button {
  width: 10vw;
  height: 5vh;
  margin-top: 2vh;
  background-color: crimson;
  color: white;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 0.7vw;
  transition: all 0.3s ease;
}

.remove-button:hover {
  opacity: 0.6;
}

.continue-button {
  width: 10vw;
  height: 5vh;
  margin-top: 2vh;
  background-color: var(--classi2);
  color: white;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 0.7vw;
  transition: all 0.3s ease;
}

.preview-content {
  background-color: var(--accent1);
  padding: 2rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.preview-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--classi2);
  color: white;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  transition: all ease 0.3s;
  padding: 2vh 2vw;
  margin: 2rem 0 0.25rem 0;
  font-size: 1vw;
}

.question-title {
  flex: 5;
}

.question-number {
  color: darkgrey;
  font-size: 0.8vw;
}

.question-content {
  font-size: 1.1vw;
}

.grade {
  cursor: default;
  display: flex;
  color: var(--primary);
  justify-content: center;
  font-size: 1.2vw;
}
</style>
