<template>
  <div
    ref="tiptapContainer"
    v-if="editor"
    :class="['tiptap-container', 'fade-in-out', { expanded: isExpanded }]"
  >
    <div v-if="isExpanded" class="control-group sticky-toolbar">
      <div class="button-group">
        <button
          @click="editor.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          <Tooltip text="Bold">
            <span class="material-symbols-outlined"> format_bold </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <Tooltip text="Italic">
            <span class="material-symbols-outlined"> format_italic </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleStrike().run()"
          :disabled="!editor.can().chain().focus().toggleStrike().run()"
          :class="{ 'is-active': editor.isActive('strike') }"
        >
          <Tooltip text="Strikethrough">
            <span class="material-symbols-outlined"> strikethrough_s </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleCodeBlock().run()"
          :class="{ 'is-active': editor.isActive('codeBlock') }"
        >
          <Tooltip text="Insert code block">
            <span class="material-symbols-outlined"> code </span>
          </Tooltip>
        </button>
        <button @click="editor.commands.unsetAllMarks()">
          <Tooltip text="Clear formatting">
            <span class="material-symbols-outlined"> format_clear </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{
            'is-active': editor.isActive('heading', {
              level: 1,
            }),
          }"
        >
          <Tooltip text="Heading 1">
            <span class="material-symbols-outlined"> format_h1 </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{
            'is-active': editor.isActive('heading', {
              level: 2,
            }),
          }"
        >
          <Tooltip text="Heading 2">
            <span class="material-symbols-outlined"> format_h2 </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{
            'is-active': editor.isActive('heading', {
              level: 3,
            }),
          }"
        >
          <Tooltip text="Heading 3">
            <span class="material-symbols-outlined"> format_h3 </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <Tooltip text="Bullet list">
            <span class="material-symbols-outlined">
              format_list_bulleted
            </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          <Tooltip text="Numbered list">
            <span class="material-symbols-outlined">
              format_list_numbered
            </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'is-active': editor.isActive('blockquote') }"
        >
          <Tooltip text="Block quote">
            <span class="material-symbols-outlined"> format_quote </span>
          </Tooltip>
        </button>
        <button @click="editor.chain().focus().setHorizontalRule().run()">
          <Tooltip text="Horizontal rule">
            <span class="material-symbols-outlined"> horizontal_rule </span>
          </Tooltip>
        </button>

        <button
          @click="editor.chain().focus().undo().run()"
          :disabled="!editor.can().chain().focus().undo().run()"
        >
          <Tooltip text="Undo">
            <span class="material-symbols-outlined"> undo </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().redo().run()"
          :disabled="!editor.can().chain().focus().redo().run()"
        >
          <Tooltip text="Redo">
            <span class="material-symbols-outlined"> redo </span>
          </Tooltip>
        </button>

        <div>
          <button @click="triggerColorPicker">
            <Tooltip text="Text color">
              <span
                class="material-symbols-outlined"
                :style="{
                  color: editor.getAttributes('textStyle').color,
                }"
              >
                format_color_text
              </span>
            </Tooltip>
          </button>
          <input
            ref="colorPicker"
            type="color"
            @input="setColor"
            :value="editor.getAttributes('textStyle').color"
            class="hidden-color-picker"
          />
        </div>

        <div>
          <button @click="triggerHighlightPicker">
            <Tooltip text="Highlighter">
              <span
                class="material-symbols-outlined"
                :style="{
                  color: editor.getAttributes('highlight').color,
                }"
              >
                format_ink_highlighter
              </span>
            </Tooltip>
          </button>
          <input
            ref="highlightPicker"
            type="color"
            @input="setHighlightColor"
            :value="editor.getAttributes('highlight').color"
            class="hidden-highlight-picker"
          />
        </div>
      </div>
    </div>
    <editor-content
      ref="editor"
      :class="['editor', { editorExpanded: isExpanded }]"
      :editor="editor"
      tabindex="0"
      @mousedown="focusEditor"
    />

    <button v-if="isExpanded" class="save-button" @click="emitContent">
      Save
    </button>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import Color from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import Placeholder from "@tiptap/extension-placeholder";
import Tooltip from "./Tooltip.vue";

export default {
  components: {
    EditorContent,
    Highlight,
    Typography,
    Placeholder,
    Tooltip,
  },

  props: ["editable", "content"],

  data() {
    return {
      editor: null,
      isExpanded: false,
    };
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        Color.configure({ types: [TextStyle.name, ListItem.name] }),
        TextStyle.configure({ types: [ListItem.name] }),
        StarterKit,
        Placeholder.configure({
          placeholder: "Your answer here...",
        }),
        Highlight.configure({ multicolor: true }),
      ],
      content: `<p></p>`,
      editable: this.editable,
    });

    this.editor.commands.insertContent(this.content.response_content);

    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    this.editor.destroy();
  },

  methods: {
    focusEditor() {
      this.editor.commands.focus();
      this.isExpanded = true;
    },
    emitContent() {
      this.isExpanded = false;
      const jsonContent = this.editor.getJSON();

      if (!this.editor.state.doc.textContent.trim().length) {
        this.$emit("update:content", null);
      } else {
        this.$emit("update:content", jsonContent);
      }
    },
    handleClickOutside(event) {
      const tiptapContainer = this.$refs.tiptapContainer;
      if (tiptapContainer && !tiptapContainer.contains(event.target)) {
        this.isExpanded = false;
      }
    },
    triggerColorPicker() {
      this.$refs.colorPicker.click();
    },
    setColor(event) {
      this.editor.chain().focus().setColor(event.target.value).run();
    },
    triggerHighlightPicker() {
      this.$refs.highlightPicker.click();
    },
    setHighlightColor(event) {
      const color = event.target.value;
      this.editor.chain().focus().toggleHighlight({ color }).run();
    },
  },
};
</script>

<style lang="scss">
.tiptap-container {
  position: relative;
  width: 100%;
  height: 10vh;
  // min-height: 30vh;
  // max-height: 65vh;
  background-color: var(--accent3);
  // background-color: rgba(255, 255, 255, 0.6);
  color: var(--primary);
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  font-family: var(--main-font);
  transition: all ease 0.7s;
  overflow: auto;
  font-size: 0.9vw;
}

.tiptap-container.expanded {
  height: 53vh;
}

.sticky-toolbar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 10;
}

.control-group {
  padding: 0.5rem;
  border-bottom: 0.05rem solid var(--primary);
  background-color: var(--accent3);
}

.button-group {
  margin: 0 1rem;
  display: flex;
  // flex-direction: column;
}

.control-group button {
  cursor: pointer;
  color: var(--primary);
  background-color: var(--accent3);
  border: none;
  border-radius: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
}

.control-group button:hover {
  background-color: var(--primary);
  color: var(--secondary);
  transition: all ease 0.3s;
}

.editor {
  height: fit-content;
  cursor: text;
  padding: 2vh 2vw;
  line-height: 1.5rem;
  transition: all 0.5s ease;
}

.editorExpanded {
  height: 45vh;
}

.save-button {
  position: absolute;
  bottom: 2rem;
  left: 88%;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  float: right;
  margin: 1rem 2rem;
  height: 5vh;
  width: 5vw;
  border: none;
  border-radius: 1rem;
  background-color: var(--classi2);
  color: white;
}

.save-button:hover {
  opacity: 0.6;
  transition: all ease 0.3s;
}

.tiptap {
  outline: none;

  :first-child {
    margin-top: 0;
  }

  strong {
    font-weight: 700;
  }

  ul,
  ol {
    padding: 0 3rem;
    // margin: 1.25rem 1rem 1.25rem 0.4rem;

    // li p {
    //     margin-top: 0.25em;
    //     margin-bottom: 0.25em;
    // }
  }

  h1,
  h2,
  h3 {
    text-wrap: pretty;
    margin-bottom: 0.3vh;
  }

  h1 {
    font-size: 1.5vw;
  }

  h2 {
    font-size: 1.3vw;
  }

  h3 {
    font-size: 1.1vw;
  }

  /* Code and preformatted text styles */
  code {
    border-left: 0.2rem solid var(--primary);
    font-size: 0.8rem;
    padding: 0 0 0 1rem;
    margin: 0;
  }

  pre {
    border-radius: 0.5rem;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      border-left: 0.2rem solid var(--primary);
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--primary);
  }

  p.is-editor-empty:first-child::before {
    color: var(--gray-4);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}

.hidden-color-picker {
  position: relative;
  width: 0px;
  right: 2.2vw;
  opacity: 0;
  pointer-events: none;
  margin: none;
}

.hidden-highlight-picker {
  position: relative;
  right: 2.2vw;
  width: 0px;
  opacity: 0;
  pointer-events: none;
  padding: none;
}
</style>
