<template>
    <div :class="['main-container', { collapsed: isCollapsed }]">
        <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
        <div :class="['content-container', { collapsed: isCollapsed }]">
            <StudentHeader title="Grades" />
            <LoadingSpinner class="spinner" v-if="loading" />

            <div class="profile-container">
                <div class="profile-header">
                    <img :src="user.avatar" class="profile-avatar" />
                    <h2>
                        {{ studentStore.firstName }}
                        {{ studentStore.lastName }}
                    </h2>
                    <p>{{ studentStore.email }}</p>
                </div>
                <div class="profile-sections">
                    <div class="profile-section">
                        <div class="section-title">Personal Information</div>
                        <div class="section-content">
                            <p>Student ID: {{ studentStore.studentId }}</p>
                            <p>
                                Date of Birth: June 1, 2001
                                <!-- {{studentStore.enrollmentDate}} -->
                            </p>
                            <p>Class: {{ studentStore.class }}</p>
                            <p>Section: {{ studentStore.section }}</p>
                        </div>
                    </div>
                    <div class="profile-section">
                        <div class="section-title">Settings</div>
                        <div class="section-content">
                            <label>
                                <input
                                    type="checkbox"
                                    v-model="settings.emailNotifications"
                                />
                                Receive email notifications
                            </label>

                            <label
                                >Language:
                                <select v-model="settings.language">
                                    <option value="en">English</option>
                                    <option value="es">Espa�ol</option>
                                    <option value="fr">Fran�ais</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div class="profile-section">
                        <div class="section-title">Account</div>
                        <div class="section-content">
                            <button
                                @click="changePassword"
                                class="action-button"
                            >
                                Change Password
                            </button>
                            <button @click="enable2FA" class="action-button">
                                Enable Two-Factor Authentication
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import StudentHeader from "@/components/StudentHeader.vue";
import { useStudentStore } from "@/store/studentData";

export default {
    name: "StudentProfile",
    components: {
        Sidebar,
        StudentHeader,
    },
    data() {
        return {
            loading: false,
            isCollapsed: false,
            user: {
                name: "John Snow",
                email: "john.snow@example.com",
                avatar: require("@/assets/JohnSnow.jpg"),
                studentId: "JS12345",
                dateOfBirth: "1995-05-15",
                major: "Medieval Studies",
                year: "Senior",
            },
            settings: {
                emailNotifications: true,
                darkMode: false,
                language: "en",
            },
        };
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
    },
    methods: {
        handleToggle(collapsed) {
            this.isCollapsed = !collapsed;
        },
        changePassword() {
            alert("Coming soon");
        },
        enable2FA() {
            alert("Coming soon");
        },
    },
};
</script>

<style scoped>
.main-container {
    display: flex;
    transition: all 0.3s ease-in-out;
    font-family: var(--main-font);
}

.main-container.collapsed .content-container {
    margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
    margin-left: 18vw;
}

.content-container {
    flex: 1;
    transition: margin-left 0.3s ease-in-out;
    color: var(--primary);
}

.profile-header {
    text-align: center;
    margin-bottom: 2vh;
}

.profile-avatar {
    width: 15vw;
    height: 15vw;
    border-radius: 50%;
    object-fit: cover;
}

.profile-sections {
    display: flex;
    flex-direction: column;
}

.profile-section {
    display: flex;
    margin: 1vh;
    background-color: var(--accent3);
    color: var(--primary);
    border-radius: 1rem;
    box-shadow: var(--box-shadow);
}

.section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 12vw;
    font-size: 1.4vw;
    border-right: 0.2rem solid var(--classi2);
    margin: 2vh 2vw;
    padding-right: 2vw;
}

.section-content {
    display: flex;
    flex-direction: column;
    padding: 2vh 2vw;
    line-height: 2;
    font-size: 1vw;
}

.action-button {
    background-color: var(--classi2);
    color: white;
    border: none;
    padding: 1vh 0.5vw;
    margin-bottom: 1vh;
    border-radius: 1rem;
    cursor: pointer;
    transition: all ease 0.3s;
}

.action-button:hover {
    background-color: var(--primary);
    color: var(--secondary);
}

h1,
h2,
h3 {
    color: var(--text-primary);
}

p,
label {
    color: var(--text-secondary);
}

select {
    margin-left: 10px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
}
</style>
