import { defineStore } from "pinia";
import axiosInstance from "@/utils/axiosInstance";

export const useStudentStore = defineStore("studentData", {
  state: () => ({
    studentId: -1,
    email: "",
    firstName: "",
    lastName: "",
    class: "",
    section: "",
    enrollmentDate: "",
    isAuthenticated: false,
    courses: [],
    timetable: [],
    sessions: 0,
  }),
  getters: {
    getFirstName: (state) => state.firstName,
  },
  actions: {
    async login() {
      this.isAuthenticated = true;
    },
    async checkAuth() {
      try {
        await axiosInstance.get("/auth/student-protected");
        this.isAuthenticated = true;
      } catch (error) {
        this.isAuthenticated = false;
      }
      return this.isAuthenticated;
    },
    async logout() {
      this.isAuthenticated = false;
      localStorage.removeItem("studentData");
      sessionStorage.removeItem("welcomed");
      await axiosInstance.post("/auth/logout");
    },
    setStudentData(
      studentId,
      email,
      firstName,
      lastName,
      _class,
      section,
      enrollmentDate,
      sessions
    ) {
      this.studentId = studentId;
      this.email = email;
      this.firstName = firstName;
      this.lastName = lastName;
      this.class = _class;
      this.section = section;
      this.enrollmentDate = enrollmentDate;
      this.sessions = sessions;
    },

    getCourse(courseCode) {
      return this.courses.filter((course) => course.course_code === courseCode);
    },

    async retrieveStudentDataFromBackend() {
      try {
        const studentResponse = await axiosInstance.get(
          "/students/get-student"
        );
        const data = studentResponse.data;
        this.setStudentData(
          data.studentId,
          data.email,
          data.firstName,
          data.lastName,
          data.class,
          data.section,
          data.enrollmentDate,
          data.sessions
        );
      } catch (error) {
        console.log("User not found", error);
      }

      // Get student's courses
      try {
        const coursesResponse = await axiosInstance.get(
          "/students/get-courses"
        );
        this.courses = coursesResponse.data;
      } catch (error) {
        console.log("Courses not found", error);
      }

      // Get student's timetable
      try {
        const timetableResponse = await axiosInstance.get(
          "/students/get-timetable"
        );
        this.timetable = timetableResponse.data;
      } catch (error) {
        console.log("Timetable not found", error);
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [{ storage: localStorage }],
  },
});
