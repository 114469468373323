<template>
  <div :class="['main-container', { collapsed: isCollapsed }]">
    <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
    <div :class="['content-container', { collapsed: isCollapsed }]">
      <StudentHeader title="Assignments" />
      <LoadingSpinner class="spinner" v-if="loading" />
      <div v-if="!loading && isMainContent">
        <div class="top-bar">
          <FilterMenu
            :filters="filters"
            :subjects="subjects"
            @clearFilters="clearFilters"
          />
          <button class="calendar-button" @click="goToCalendar">
            View on Calendar
            <span class="material-symbols-outlined calendar-icon"> event </span>
          </button>
        </div>
        <TaskList
          title="Upcoming"
          :tasks="upcomingTasks"
          :filters="filters"
          @assignmentActivated="goToAssignment"
        />
        <br /><br />
        <TaskList
          title="Past"
          :tasks="pastTasks"
          :filters="filters"
          @assignmentActivated="goToAssignment"
        />
      </div>
      <router-view v-if="!loading && !isMainContent"></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import StudentHeader from "@/components/StudentHeader.vue";
import StudentAssignment from "@/components/StudentAssignment.vue";
import TaskList from "@/components/TaskList.vue";
import FilterMenu from "@/components/FilterMenu.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useAssignmentStore } from "@/store/assignmentData";

export default {
  name: "StudentAssignments",
  components: {
    Sidebar,
    StudentHeader,
    TaskList,
    FilterMenu,
    StudentAssignment,
    LoadingSpinner,
  },
  props: {
    selectedFilters: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isCollapsed: false,
      loading: false,
      filters: {
        completion: {
          graded: false,
          submitted: false,
          inProgress: false,
          incomplete: false,
        },
        subjects: [],
        dueDate: {
          today: false,
          thisWeek: false,
          thisMonth: false,
        },
      },
    };
  },
  computed: {
    assignmentStore() {
      return useAssignmentStore();
    },
    isMainContent() {
      return this.$route.path === "/student/assignments";
    },
    assignments() {
      return Object.values(this.assignmentStore.assignments);
    },
    upcomingTasks() {
      const now = new Date();
      return this.assignments.filter((task) => new Date(task.due) >= now);
    },
    pastTasks() {
      const now = new Date();
      return this.assignments.filter((task) => new Date(task.due) < now);
    },
    subjects() {
      return [...new Set(this.assignments.map((task) => task.course))];
    },
  },
  methods: {
    goToCalendar() {
      this.$router.push("/calendar");
    },
    handleToggle(collapsed) {
      this.isCollapsed = !collapsed;
    },
    clearFilters() {
      this.filters = {
        completion: {
          graded: false,
          submitted: false,
          inProgress: false,
          incomplete: false,
        },
        subjects: [],
        dueDate: { today: false, thisWeek: false, thisMonth: false },
      };
    },
    goToAssignment(assignment) {
      this.$router.push({
        name: "StudentAssignment",
        params: { assignmentId: assignment.assignment_id },
      });
    },
    goBackToMain() {
      this.activeAssignment = null;
    },
  },
  mounted() {
    // Check if filters need to be applied on mount
    if (this.selectedFilters !== "") {
      this.filters["completion"][this.selectedFilters] = true;
    }

    this.activeAssignment = null;
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  transition: all 0.3s ease-in-out;
  font-family: var(--main-font);
}

.main-container.collapsed .content-container {
  margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
  margin-left: 18vw;
}

.content-container {
  flex: 1;
  transition: margin-left 0.3s ease-in-out;
  color: var(--primary);
  font-size: 1.2rem;
}

.spinner {
  margin: 20vh auto;
}

.top-bar {
  height: 4vh;
  display: flex;
  justify-content: space-between;
  margin-top: 4vh;
  margin-bottom: 2vh;
}

.calendar-button {
  margin-right: 1vw;
  background-color: var(--classi2);
  color: white;
  height: 4vh;
  width: 12vw;
  border: none;
  border-radius: 0.7vw;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8vw;
}

.calendar-button:hover {
  opacity: 0.6;
}

.calendar-icon {
  padding-left: 0.5vw;
}
</style>
