<template>
  <div class="main-container">
    <StudentHeader title="Autograder" />
    <div class="content-container">
      <div
        class="sliding-menu"
        :style="{ transform: `translateX(-${currentStep * 33.33}%)` }"
      >
        <!-- Step 1: Upload Marking Scheme -->
        <div class="step-container">
          <div class="uploader">
            <p class="step-heading">Upload a marking scheme</p>
            <p class="step-description">
              Please upload a marking scheme in PDF form that will be used to
              guide the autograder. This could be typed or handwritten
              (scanned).
            </p>
            <FileUploader
              v-if="!markingSchemeUrl"
              @files-selected="(files) => loadFiles(files, 'markingScheme')"
            />
            <FileDisplay
              v-else
              :fileUrl="markingSchemeUrl"
              :isPDF="isMarkingSchemePDF"
              :loading="markingSchemeLoading"
              :success="markingSchemeSuccess"
              fileType="Marking Scheme"
              @remove="removeFile('markingScheme')"
              @continue="handleContinue('markingScheme')"
            />
          </div>
        </div>

        <!-- Step 2: Upload Submissions -->
        <div class="step-container">
          <div class="flex-container">
            <div
              v-if="Object.keys(submissions).length > 0"
              class="submissions-list"
            >
              <div class="submissions-list-heading">Submissions</div>
              <div
                v-for="(submission, fileName) in submissions"
                :key="fileName"
                class="submission-item"
                :class="{ active: currentSubmissionName === fileName }"
                @click="setCurrentSubmission(fileName)"
              >
                {{ fileName }}
                <span
                  v-if="!submissionLoading"
                  class="material-symbols-outlined remove-icon"
                  @click.stop="removeSubmission(fileName)"
                  >close</span
                >
              </div>
            </div>
            <div class="uploader">
              <p class="step-heading">Upload submissions for grading</p>
              <p class="step-description">
                Please upload students' submissions in PDF form to be graded.
                These could be typed or handwritten (scanned).
              </p>
              <FileUploader
                v-if="Object.keys(submissions).length === 0"
                @files-selected="handleSubmissionsUpload"
                multiple
              />
              <FileDisplay
                v-if="currentSubmission"
                :fileUrl="currentSubmission.url"
                :isPDF="currentSubmission.isPDF"
                :loading="submissionLoading"
                :success="submissionSuccess"
                fileType="Submission"
                @remove="removeSubmission(currentSubmissionName)"
                @continue="handleContinue('submission')"
              />
            </div>
          </div>
        </div>

        <!-- Step 3: Display Graded Submission -->
        <div class="step-container">
          <div class="flex-container">
            <div
              v-if="Object.keys(submissions).length > 0"
              class="submissions-list"
            >
              <div class="submissions-list-heading">
                Submissions
                <span
                  v-if="gradingComplete"
                  class="material-symbols-outlined remove-icon"
                  @click="downloadAll"
                  title="Download all"
                  >download</span
                >
              </div>
              <div
                v-for="(submission, fileName) in submissions"
                :key="fileName"
                class="submission-item"
                :class="{ active: currentSubmissionName === fileName }"
                @click="setCurrentSubmission(fileName)"
              >
                {{ fileName }}
                <!-- <span v-if="totalPoints[fileName]">
                  [{{ totalPoints[fileName].obtained.toFixed(2) }} /
                  {{ totalPoints[fileName].possible.toFixed(2) }}]</span
                > -->
                <span
                  v-if="gradingComplete"
                  class="material-symbols-outlined remove-icon"
                  @click="generatePDF(fileName)"
                  >download</span
                >
              </div>
            </div>
            <div
              class="graded-container"
              v-for="(submission, fileName) in submissions"
              :key="fileName"
              v-show="currentSubmissionName === fileName"
            >
              <div class="graded-total" v-if="totalPoints[fileName]">
                Total: {{ totalPoints[fileName].obtained.toFixed(2) }} /
                {{ totalPoints[fileName].possible.toFixed(2) }}
              </div>
              <div
                v-for="(question, index) in gradedQuestions[fileName]"
                :key="index"
                class="graded-question"
              >
                <div class="question-info">
                  <div class="question-header">
                    <div class="section-break"></div>
                    <p class="graded-question-section">
                      Question {{ question.question_number }}
                    </p>
                  </div>
                  <TeacherTiptap
                    class="wysiwyg"
                    v-model:content="question.question"
                    :editable="false"
                  />
                  <div class="question-header">
                    <div class="section-break"></div>
                    <p class="graded-question-section">Response</p>
                    <div class="show-answer-icon">
                      <span
                        class="material-symbols-outlined"
                        @mousedown="
                          () => {
                            showAnswerMode = question.question_number;
                          }
                        "
                        @mouseup="
                          () => {
                            showAnswerMode = -1;
                          }
                        "
                        @mouseleave="
                          () => {
                            showAnswerMode = -1;
                          }
                        "
                      >
                        visibility</span
                      >
                    </div>
                  </div>
                  <TeacherTiptap
                    class="wysiwyg"
                    v-if="showAnswerMode === question.question_number"
                    v-model:content="question.answer"
                    :editable="false"
                  />
                  <TeacherTiptap
                    class="wysiwyg"
                    v-else
                    v-model:content="question.response"
                    :editable="false"
                  />
                </div>

                <div class="question-feedback">
                  <div
                    v-if="question.points_obtained === -1"
                    class="spinner-container"
                  >
                    <LoadingSpinner class="feedback-spinner" />
                    <p class="loading-text">Grading in progress</p>
                  </div>
                  <div v-else>
                    <div class="question-grade">
                      <input
                        v-model.number="question.points_obtained"
                        type="number"
                        :min="0"
                        :max="question.points_possible"
                        step="0.25"
                        @change="updateResponse(question)"
                      />
                      / {{ question.points_possible }}
                    </div>
                    <div class="edit-controls">
                      <div
                        v-if="feedbackEditMode === question.question_number"
                        class="edit-option"
                        @click="
                          cancelFeedback(fileName, question.question_number)
                        "
                      >
                        Cancel
                        <span class="material-symbols-outlined">close</span>
                      </div>
                      <div
                        v-if="feedbackEditMode === question.question_number"
                        class="edit-option"
                        @click="
                          saveFeedback(fileName, question.question_number)
                        "
                      >
                        Save
                        <span class="material-symbols-outlined">check</span>
                      </div>
                      <div v-else>
                        <span
                          class="material-symbols-outlined"
                          @click="feedbackEditMode = question.question_number"
                          >edit</span
                        >
                      </div>
                    </div>
                    <TeacherTiptap
                      class="wysiwyg"
                      v-model:content="question.feedback"
                      :editable="feedbackEditMode === question.question_number"
                      whiteMode="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import FileUploader from "@/components/FileUploader.vue";
import FileDisplay from "@/components/FileDisplay.vue";
import TeacherTiptap from "@/components/TeacherTiptap.vue";
import axiosInstance from "@/utils/axiosInstance";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { nextTick } from "vue";

export default {
  name: "InstructorGrading",
  components: {
    StudentHeader,
    FileUploader,
    FileDisplay,
    TeacherTiptap,
    LoadingSpinner,
  },
  data() {
    return {
      currentStep: 0,
      markingSchemeUrl: null,
      isMarkingSchemePDF: false,
      submissions: {},
      currentSubmissionName: null,
      feedbackEditMode: -1,
      showAnswerMode: -1,
      markingSchemeLoading: false,
      markingSchemeSuccess: false,
      markingSchemeId: -1,
      submissionLoading: false,
      submissionSuccess: false,
      currentFiles: {
        markingScheme: null,
      },
      gradedQuestions: {},
      hideAnswer: true,
      totalPoints: {},
      gradingComplete: false,
    };
  },
  computed: {
    currentSubmission() {
      return this.submissions[this.currentSubmissionName] || null;
    },
  },
  methods: {
    sanitizeHTML(str) {
      const tempDiv = document.createElement("div");
      tempDiv.textContent = str;
      return tempDiv.innerHTML;
    },
    escapeBackslashes(str) {
      return str.replace(/\\/g, "\\\\");
    },
    loadFiles(files, type) {
      for (const file of files) {
        const isPDF = file.type === "application/pdf";
        const url = URL.createObjectURL(file);
        if (type === "markingScheme") {
          this.markingSchemeUrl = url;
          this.isMarkingSchemePDF = isPDF;
          this.currentFiles.markingScheme = file;
        } else if (type === "submission") {
          this.submissions[file.name] = {
            file,
            url,
            isPDF,
            loading: false,
            success: false,
          };
        }
      }
      if (type === "submission" && !this.currentSubmissionName) {
        this.setCurrentSubmission(Object.keys(this.submissions)[0]);
      }
    },
    showCorrectAnswer(question) {
      question.displayedResponse = question.answer;
    },
    hideCorrectAnswer(question) {
      question.displayedResponse = question.response;
    },
    handleSubmissionsUpload(files) {
      this.loadFiles(files, "submission");
    },
    setCurrentSubmission(fileName) {
      this.currentSubmissionName = fileName;
    },
    saveFeedback(fileName, question_number) {
      this.feedbackEditMode = -1;
      this.gradedQuestions[fileName][question_number].original_feedback =
        this.gradedQuestions[fileName][question_number].feedback;
      const question = this.gradedQuestions[fileName][question_number];
      this.updateResponse(question);
    },
    cancelFeedback(fileName, question_number) {
      this.feedbackEditMode = -1;

      // Directly update the feedback property
      this.gradedQuestions[fileName][question_number].feedback =
        this.gradedQuestions[fileName][question_number].original_feedback;

      console.log(
        "Original feedback:",
        this.gradedQuestions[fileName][question_number].original_feedback
      );
      console.log(
        "Current feedback:",
        this.gradedQuestions[fileName][question_number].feedback
      );
    },

    removeSubmission(fileName) {
      URL.revokeObjectURL(this.submissions[fileName].url);
      delete this.submissions[fileName];
      if (Object.keys(this.submissions).length === 0) {
        this.currentSubmissionName = null;
      } else if (this.currentSubmissionName === fileName) {
        this.setCurrentSubmission(Object.keys(this.submissions)[0]);
      }
    },
    handleContinue(type) {
      if (type === "markingScheme" && this.currentFiles.markingScheme) {
        this.handleMarkingSchemeUpload(this.currentFiles.markingScheme);
      } else if (
        type === "submission" &&
        Object.keys(this.submissions).length > 0
      ) {
        this.handleSubmissionUpload();
      }
    },
    async handleMarkingSchemeUpload(file) {
      await this.uploadFile(
        file,
        "markingScheme",
        "/grader/process-marking-scheme"
      );
      this.goToNextStep();
    },
    async handleSubmissionUpload() {
      await this.uploadFile(null, "submission", "/grader/process-submission");
      this.goToNextStep();
      this.gradeSubmissions();
    },
    async uploadFile(file, type, endpoint) {
      this[`${type}Loading`] = true;
      try {
        const formData = new FormData();

        if (type === "markingScheme") {
          formData.append("file", file);
          formData.append("title", file.name);
          formData.append("marking_scheme_id", this.markingSchemeId);
          const response = await axiosInstance.post(endpoint, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          const data = response.data;
          this.markingSchemeId = data["marking_scheme_id"];
        }

        if (type === "submission") {
          for (const fileName in this.submissions) {
            formData.append(
              "files[]",
              this.submissions[fileName].file,
              fileName
            );
          }

          formData.append("marking_scheme_id", this.markingSchemeId);

          const response = await axiosInstance.post(endpoint, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          const data = response.data;

          // Iterate over the files (submission keys)
          for (const fileName in data) {
            if (data.hasOwnProperty(fileName)) {
              // Initialize a gradedQuestions object for this specific file
              this.gradedQuestions[fileName] =
                this.gradedQuestions[fileName] || {};

              // Iterate over the questions associated with the file
              for (const question of data[fileName]) {
                // Set points_obtained, points_possible, and feedback for each question
                question["response_id"] = -1;
                question["points_obtained"] = -1;
                question["points_possible"] = -1;
                question["feedback"] = "";
                question["original_feedback"] = "";

                // Store the question in the gradedQuestions object under the file name
                this.gradedQuestions[fileName][question["question_number"]] =
                  question;
                this.totalPoints[fileName] = {
                  possible: 0,
                  obtained: 0,
                };
              }
            }
          }
        }

        console.log(this.gradedQuestions);

        this[`${type}Loading`] = false;
        this[`${type}Success`] = true;

        setTimeout(() => {
          this[`${type}Success`] = false;
        }, 1000);
      } catch (error) {
        console.error(`Error processing ${type}:`, error);
        this[`${type}Loading`] = false;
      }
    },
    removeFile(type) {
      if (type === "markingScheme") {
        URL.revokeObjectURL(this.markingSchemeUrl);
        this.markingSchemeUrl = null;
        this.currentFiles.markingScheme = null;
      }
    },
    goToNextStep() {
      this.currentStep += 1;
    },
    async gradeSubmissions() {
      await this.gradeSubmission(Object.keys(this.gradedQuestions));
    },
    async gradeSubmission(submissions) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/grader/grade-submission`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              marking_scheme_id: this.markingSchemeId,
              file_names: submissions,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunkText = decoder.decode(value, { stream: true });
          const gradedQuestion = JSON.parse(chunkText);

          var fileName = gradedQuestion["file_name"];
          var questionNumber = gradedQuestion["question_number"];
          var response_id = gradedQuestion["response_id"];
          var pointsObtained = gradedQuestion["points_obtained"];
          var pointsPossible = gradedQuestion["points_possible"];
          var feedback = gradedQuestion["feedback"];

          this.totalPoints[fileName].possible += pointsPossible;
          this.totalPoints[fileName].obtained += pointsObtained;

          this.gradedQuestions[fileName][questionNumber].response_id =
            response_id;
          this.gradedQuestions[fileName][questionNumber].points_obtained =
            pointsObtained;
          this.gradedQuestions[fileName][questionNumber].points_possible =
            pointsPossible;
          this.gradedQuestions[fileName][questionNumber].feedback = feedback;
          this.gradedQuestions[fileName][questionNumber].original_feedback =
            feedback;
        }

        this.gradingComplete = true;
      } catch (error) {
        console.error("Error grading submission:", error);
      }
    },
    extractRawLaTeX(content) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = content;
      const mathElements = tempDiv.querySelectorAll(".math");
      mathElements.forEach((elem) => {
        const rawLaTeX = elem.getAttribute("data-latex") || elem.textContent;
        elem.textContent = rawLaTeX;
      });
      return tempDiv.innerHTML;
    },

    async generatePDF(fileName) {
      const contentDiv = document.createElement("div");
      contentDiv.style.lineHeight = "2";
      contentDiv.style.fontFamily = "'Noto Sans', sans-serif";

      const titleDiv = document.createElement("div");
      titleDiv.innerHTML = `
        <h1>Graded Submission: ${fileName}</h1>
        <h2>Total Score: ${this.totalPoints[fileName].obtained.toFixed(
          2
        )} / ${this.totalPoints[fileName].possible.toFixed(2)}</h2>
      `;
      contentDiv.appendChild(titleDiv);

      const questions = this.gradedQuestions[fileName];
      for (const [questionNumber, question] of Object.entries(questions)) {
        const questionDiv = document.createElement("div");
        questionDiv.innerHTML = `
          <h3>Question ${questionNumber}</h3>
          <div class="question-content">${this.extractRawLaTeX(
            question.question
          )}</div>
          <h4>Student Response:</h4>
          <div class="student-response">${this.extractRawLaTeX(
            question.response
          )}</div>
          <h4>Correct Answer:</h4>
          <div class="correct-answer">${this.extractRawLaTeX(
            question.answer
          )}</div>
          <h4>Grade: ${question.points_obtained} / ${
          question.points_possible
        }</h4>
          <h4>Feedback:</h4>
          <div class="feedback">${this.extractRawLaTeX(question.feedback)}</div>
          <hr><br/>
        `;
        contentDiv.appendChild(questionDiv);
      }

      document.body.appendChild(contentDiv);

      await document.fonts.ready;

      if (window.MathJax) {
        await MathJax.typesetPromise([contentDiv]);
      }

      // Remove script elements containing raw LaTeX code
      contentDiv.querySelectorAll("script").forEach((el) => el.remove());
      contentDiv
        .querySelectorAll(
          ".MathJax_Preview, .MJX_Assistive_MathML, .MathJax_SVG_Hidden, .MathJax_CHTML_Hidden"
        )
        .forEach((el) => el.remove());
      contentDiv
        .querySelectorAll('[style*="display: none"]')
        .forEach((el) => el.remove());

      const opt = {
        margin: [20, 20, 20, 20],
        filename: `${fileName}_graded.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      try {
        await html2pdf().from(contentDiv).set(opt).save();
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        document.body.removeChild(contentDiv);
      }
    },
    async downloadAll() {
      const zip = new JSZip();

      for (const fileName in this.gradedQuestions) {
        const pdfBlob = await this.generatePDFBlob(fileName);
        zip.file(`${fileName}_graded.pdf`, pdfBlob);
      }

      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "graded_submissions.zip");
    },

    async generatePDFBlob(fileName) {
      const contentDiv = document.createElement("div");
      contentDiv.style.lineHeight = "2";
      contentDiv.style.fontFamily = "'Noto Sans', sans-serif";

      const titleDiv = document.createElement("div");
      titleDiv.innerHTML = `
          <h1>Graded Submission: ${fileName}</h1>
          <h2>Total Score: ${this.totalPoints[fileName].obtained.toFixed(
            2
          )} / ${this.totalPoints[fileName].possible.toFixed(2)}</h2>
        `;
      contentDiv.appendChild(titleDiv);

      const questions = this.gradedQuestions[fileName];
      for (const [questionNumber, question] of Object.entries(questions)) {
        const questionDiv = document.createElement("div");
        questionDiv.innerHTML = `
            <h3>Question ${questionNumber}</h3>
            <div class="question-content">${this.extractRawLaTeX(
              question.question
            )}</div>
            <h4>Student Response:</h4>
            <div class="student-response">${this.extractRawLaTeX(
              question.response
            )}</div>
            <h4>Correct Answer:</h4>
            <div class="correct-answer">${this.extractRawLaTeX(
              question.answer
            )}</div>
            <h4>Grade: ${question.points_obtained} / ${
          question.points_possible
        }</h4>
            <h4>Feedback:</h4>
            <div class="feedback">${this.extractRawLaTeX(
              question.feedback
            )}</div>
            <hr><br/>
          `;
        contentDiv.appendChild(questionDiv);
      }

      document.body.appendChild(contentDiv);

      await document.fonts.ready;

      if (window.MathJax) {
        await MathJax.typesetPromise([contentDiv]);
      }

      // Remove script elements containing raw LaTeX code
      contentDiv.querySelectorAll("script").forEach((el) => el.remove());
      contentDiv
        .querySelectorAll(
          ".MathJax_Preview, .MJX_Assistive_MathML, .MathJax_SVG_Hidden, .MathJax_CHTML_Hidden"
        )
        .forEach((el) => el.remove());
      contentDiv
        .querySelectorAll('[style*="display: none"]')
        .forEach((el) => el.remove());

      const opt = {
        margin: [20, 20, 20, 20],
        filename: `${fileName}_graded.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      try {
        return await html2pdf().from(contentDiv).set(opt).output("blob");
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        document.body.removeChild(contentDiv);
      }
    },
    async updateResponse(question) {
      try {
        const response = await axiosInstance.post("/grader/update-response", {
          response_id: question.response_id,
          points_obtained: question.points_obtained,
          feedback: question.feedback,
        });

        if (response.status === 200) {
          console.log("Response updated successfully");
        } else {
          console.error("Failed to update response");
        }
      } catch (error) {
        console.error("Error updating response:", error);
      }
    },
  },
};
</script>

<style scoped>
.content-container {
  width: 80vw;
  margin: auto;
  overflow: hidden;
}

.sliding-menu {
  display: flex;
  width: 300%;
  transition: transform 0.5s ease-in-out;
}

.step-container {
  width: 33.33%;
  padding: 2vw;
}

.flex-container {
  display: flex;
}

.uploader {
  flex: 1;
  max-width: 50vw;
  margin: auto;
}

.step-heading {
  font-size: 1.3vw;
  margin-bottom: 1vw;
}

.step-description {
  font-size: 1vw;
  margin-bottom: 2vw;
}

.submissions-list {
  width: 15vw;
  height: 80vh;
  border-right: 0.1rem solid var(--classi2);
  margin-right: 2vw;
  overflow-y: auto;
}

.submissions-list-heading {
  font-size: 1.4vw;
  padding: 1vw;
  border-bottom: 0.1rem solid var(--classi2);
}

.submission-item {
  padding: 1vw;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submission-item.active {
  background-color: var(--classi2);
  color: white;
}

.graded-container {
  flex: 1;
  height: 80vh;
  overflow-y: auto;
}

.graded-total {
  font-size: 2vw;
  padding: 2vw;
  color: var(--classi2);
}

.graded-question {
  display: flex;
  margin-bottom: 2vw;
  border: 0.1rem solid var(--classi2);
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

.question-info {
  flex: 3;
  padding: 1.5vw 1vw;
  font-size: 1vw;
}

.question-feedback {
  flex: 1;
  background-color: var(--classi2);
  color: white;
  padding: 1.5vw 1vw;
  font-size: 1vw;
}

.question-header {
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}

.section-break {
  width: 5%;
  height: 2px;
  background-color: var(--classi2);
  margin-right: 1vw;
}

.graded-question-section {
  font-size: 1.3vw;
  color: var(--classi2);
}

.edit-controls {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.edit-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1vw;
  border: 0.1vw solid var(--classi2);
  border-radius: 1rem;
  padding: 0.3vw;
  transition: all ease 0.3s;
}

.edit-option:hover {
  opacity: 0.6;
}

.material-symbols-outlined {
  cursor: pointer;
  font-size: 1.4vw;
  margin: 0 0.2vw;
  transition: all ease 0.3s;
}

.material-symbols-outlined:hover {
  opacity: 0.6;
}

.question-grade {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vw;
  margin: 1vw 0;
}

input[type="number"] {
  width: 5vw;
  font-size: 2vw;
  text-align: center;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 0.5rem;
}

.wysiwyg {
  margin-top: 1vh;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-text {
  margin-top: 2vh;
}

.show-answer-icon {
  margin-left: auto;
  cursor: pointer;
}

.show-answer-icon .material-symbols-outlined {
  font-size: 1.2vw;
  color: var(--classi2);
}
</style>
