<template>
  <div v-if="editor" class="tiptap-preview">
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";

export default {
  name: "TiptapPreview",
  components: {
    EditorContent,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const editor = useEditor({
      extensions: [StarterKit, Highlight, Typography],
      content: props.content,
      editable: false,
    });

    return { editor };
  },
};
</script>
