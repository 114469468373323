<template>
  <div class="aura-chatbot">
    <h1>AURA Chatbot</h1>
    <TextbookTreePicker @textbook-selected="handleTextbookSelected" />
    <ChatBox
      :selectedTextbookId="selectedTextbookId"
      :selectedTextbookTitle="selectedTextbookTitle"
      :selectedTextbookExampleQueries="selectedTextbookExampleQueries"
    />
  </div>
</template>

<script>
import TextbookTreePicker from "@/components/TextbookTreePicker.vue";
import ChatBox from "@/components/ChatBox.vue";

export default {
  name: "AuraChatbot",
  components: {
    TextbookTreePicker,
    ChatBox,
  },
  data() {
    return {
      selectedTextbookId: null,
      selectedTextbookTitle: "",
      selectedTextbookExampleQueries: [],
    };
  },
  methods: {
    handleTextbookSelected({ textbook_id, title, example_queries }) {
      this.selectedTextbookId = textbook_id;
      this.selectedTextbookTitle = title;
      this.selectedTextbookExampleQueries = example_queries;
    },
  },
};
</script>

<style scoped>
.aura-chatbot {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  color: var(--primary);
}
</style>
