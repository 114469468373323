<template>
  <div :class="['main-container', { collapsed: isCollapsed }]">
    <Sidebar
      @themeChange="handleThemeChange"
      @toggle="handleToggle"
      :collapsed="isCollapsed"
    />
    <div :class="['content-container', { collapsed: isCollapsed }]">
      <Transition name="slide-in">
        <div>
          <StudentHeader
            v-if="typingComplete"
            :title="`Hello, ${studentStore.firstName}`"
          />
          <LoadingSpinner class="spinner" v-if="loading && typingComplete" />
          <div
            v-if="renderContent && !loading && isMainContent"
            class="content"
          >
            <div class="column1">
              <div
                :class="['quick-actions', { 'actions-active': actionsActive }]"
                @click="quickActionsClick"
              >
                Quick Actions
              </div>
              <div :class="['action1', { 'show-actions': actionsActive }]">
                <div class="action-text">
                  <span class="material-symbols-outlined"> outgoing_mail </span>
                  Send email
                </div>
              </div>
              <div :class="['action2', { 'show-actions': actionsActive }]">
                <div class="action-text">
                  <span class="material-symbols-outlined"> campaign </span>
                  Make announcement
                </div>
              </div>
              <div :class="['action3', { 'show-actions': actionsActive }]">
                <div class="action-text">
                  <span class="material-symbols-outlined icon">
                    assignment_add
                  </span>
                  <p>Create assignment</p>
                </div>
              </div>
            </div>
            <div class="column2"></div>
          </div>
        </div>
      </Transition>
      <Transition name="slide-out">
        <div v-if="!typingComplete" class="welcome-message">
          <Typewriter :text="`Hello, ${studentStore.firstName}`" />
          <div class="welcome-line">
            <Typewriter text="Welcome to " :offset="2000" />
            <Typewriter
              @typing-complete="showDashboard"
              text=""
              :classi="true"
              :offset="3500"
            />
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/TeacherSidebar.vue";
import { useStudentStore } from "@/store/studentData";
import Typewriter from "@/components/Typewriter.vue";
import CompletedAssignmentsChart from "@/components/CompletedAssignmentsChart.vue";
import axiosInstance from "@/utils/axiosInstance";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useAssignmentStore } from "@/store/assignmentData";

export default {
  name: "StudentDashboard",
  components: {
    StudentHeader,
    Sidebar,
    LoadingSpinner,
    Typewriter,
  },
  data() {
    return {
      loading: true,
      isCollapsed: true,
      typingComplete: false,
      renderContent: false,
      isMainContent: true,
      actionsActive: false,
    };
  },
  computed: {
    studentStore() {
      return useStudentStore();
    },
    assignmentStore() {
      return useAssignmentStore();
    },
    assignments() {
      return this.assignmentStore.assignments;
    },
  },
  methods: {
    handleToggle(collapsed) {
      this.isCollapsed = !collapsed;
    },
    handleThemeChange(currentTheme) {
      this.theme = currentTheme;
    },
    async showDashboard() {
      this.typingComplete = true;
      await this.delay(200);
      this.isCollapsed = false;
      await this.delay(200);
      this.renderContent = true;
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    quickActionsClick() {
      this.actionsActive = !this.actionsActive;
    },
    async getWordOfTheDay() {
      try {
        const wordResponse = await axiosInstance.get(
          "/students/get-word-of-the-day"
        );
        this.wordOfTheDay = wordResponse.data;
      } catch (error) {
        console.log("Word not found", error);
      }
    },
    getAssignmentsStatus() {
      var graded = 0;
      var submitted = 0;
      var inprogress = 0;
      var incomplete = 0;

      Object.values(this.assignments).forEach((item) => {
        switch (item.status) {
          case "Graded":
            graded += 1;
            break;
          case "Submitted":
            submitted += 1;
            break;
          case "In Progress":
            inprogress += 1;
            break;
          case "Incomplete":
            incomplete += 1;
            break;
          default:
            break;
        }
      });

      this.chartData = [
        {
          label: "Graded",
          color: "rgb(115, 179, 115)",
          // hoverColor: "darkgreen",
          value: graded,
          total: graded + submitted + inprogress + incomplete,
          onClick: () => this.handleAssignmentsClick("graded"),
        },
        {
          label: "Submitted",
          color: "rgb(118, 161, 187)",
          // hoverColor: "slateblue",
          value: submitted,
          total: graded + submitted + inprogress + incomplete,
          onClick: () => this.handleAssignmentsClick("submitted"),
        },
        {
          label: "In Progress",
          color: "rgb(205, 205, 131)",
          // hoverColor: "darkyellow",
          value: inprogress,
          total: graded + submitted + inprogress + incomplete,
          onClick: () => this.handleAssignmentsClick("inProgress"),
        },
        {
          label: "Incomplete",
          color: "rgb(203, 114, 114)",
          // hoverColor: "blue",
          value: incomplete,
          total: graded + submitted + inprogress + incomplete,
          onClick: () => this.handleAssignmentsClick("incomplete"),
        },
      ];
    },
  },
  async mounted() {
    const welcomed = localStorage.getItem("welcomed");

    if (!welcomed) {
      localStorage.setItem("welcomed", true);
    } else {
      this.typingComplete = true;
      this.isCollapsed = false;
      this.renderContent = true;
    }

    await this.getWordOfTheDay();
    if (!sessionStorage.getItem("assignmentData")) {
      await this.assignmentStore.getAssignments();
    }

    this.getAssignmentsStatus();
    this.loading = false;
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  transition: all 0.3s ease-in-out;
  font-family: var(--main-font);
}

.main-container.collapsed .content-container {
  margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
  margin-left: 18vw;
}

.content-container {
  flex: 1;
  transition: margin-left 0.3s ease-in-out;
  color: var(--primary);
}

.spinner {
  margin: 20vh auto;
}

.welcome-message {
  margin-top: 30vh;
  margin-left: 10vw;
}

.welcome-line {
  display: flex;
}

.slide-out-leave-active,
.slide-in-enter-active {
  transition: all ease-out 0.5s;
}

.slide-in-enter-from,
.slide-out-leave-to {
  transform: translateY(-30vh);
  opacity: 0;
}

.content {
  display: flex;
  margin-top: 5vh;
}

.column1 {
  display: flex;
  flex-direction: column;
  width: 30vw;
  height: 80vh;
  margin: auto;
  align-items: center;
}

.quick-actions {
  height: 10vw;
  width: 10vw;
  border-radius: 50%;
  background-color: var(--classi2);
  color: var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.1vw;
}

.quick-actions:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: all ease 0.3s;
}

.actions-active {
  opacity: 0.2;
}

.actions-active:hover {
  opacity: 0.2;
}

.action1,
.action2,
.action3 {
  cursor: pointer;
  height: 8vw;
  width: 8vw;
  border-radius: 50%;
  background-color: var(--classi2);
  color: var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.7vw;
  opacity: 0;
  transition: all ease 0.3s;
}

.action-text {
  display: flex;
  flex-direction: column;
  width: 5vw;
}

.show-actions {
  opacity: 1;
}

.show-actions:hover {
  background-color: var(--primary);
  color: var(--secondary);
}

.action1 {
  position: relative;
  right: 6vw;
  bottom: 25vh;
}

.action2 {
  position: relative;
  left: 6vw;
  bottom: 39vh;
}

.action3 {
  position: relative;
  bottom: 35vh;
}

.column2 {
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: 75vh;
  margin: auto;
}
</style>
