import { defineStore } from "pinia";
import axiosInstance from "@/utils/axiosInstance";

export const useInstructorStore = defineStore("instructorData", {
  state: () => ({
    isAuthenticated: false,
    // ... other instructor-specific state
  }),
  actions: {
    async checkAuth() {
      try {
        await axiosInstance.get("/auth/instructor-protected");
        this.isAuthenticated = true;
      } catch (error) {
        this.isAuthenticated = false;
      }
      return this.isAuthenticated;
    },
    // ... other actions
  },
});
