<template>
  <div class="textbook-tree-picker">
    <Dropdown
      label="Select Education Board:"
      :options="boardOptions"
      v-model="selectedBoard"
      @change="handleBoardChange"
    />
    <Dropdown
      v-if="selectedBoard"
      label="Select Subject:"
      :options="subjectOptions"
      v-model="selectedSubject"
      @change="handleSubjectChange"
    />
    <Dropdown
      v-if="selectedSubject"
      label="Select Textbook:"
      :options="textbookOptions"
      v-model="selectedTextbook"
      @change="handleTextbookChange"
    />
  </div>
</template>

<script>
import axiosInstance from "@/utils/axiosInstance";
import Dropdown from "@/components/Dropdown.vue";

export default {
  name: "TextbookTreePicker",
  components: {
    Dropdown,
  },
  data() {
    return {
      boards: [],
      subjects: [],
      textbooks: [],
      selectedBoard: null,
      selectedSubject: null,
      selectedTextbook: null,
    };
  },
  computed: {
    boardOptions() {
      return this.boards.map(board => ({
        value: board.id,
        text: board.name,
      }));
    },
    subjectOptions() {
      return this.subjects.map(subject => ({
        value: subject.id,
        text: subject.name,
      }));
    },
    textbookOptions() {
      return this.textbooks.map(textbook => ({
        value: textbook.textbook_id,
        text: textbook.title,
      }));
    },
  },
  methods: {
    async fetchBoards() {
      try {
        const response = await axiosInstance.get("/textbooks/boards");
        this.boards = response.data;
      } catch (error) {
        console.error("Error fetching boards:", error);
      }
    },
    async fetchSubjects(boardId) {
      try {
        const response = await axiosInstance.get(`/textbooks/subjects?board_id=${boardId}`);
        this.subjects = response.data;
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    },
    async fetchTextbooks(subjectId) {
      try {
        const response = await axiosInstance.get(`/textbooks/textbooks?subject_id=${subjectId}`);
        this.textbooks = response.data;
      } catch (error) {
        console.error("Error fetching textbooks:", error);
      }
    },
    async handleBoardChange(boardId) {
      this.selectedSubject = null;
      this.selectedTextbook = null;
      this.subjects = [];
      this.textbooks = [];
      await this.fetchSubjects(boardId);
    },
    async handleSubjectChange(subjectId) {
      this.selectedTextbook = null;
      this.textbooks = [];
      await this.fetchTextbooks(subjectId);
    },
    handleTextbookChange(textbookId) {
      const selectedTextbook = this.textbooks.find(t => t.textbook_id === textbookId);
      if (selectedTextbook) {
        this.$emit("textbook-selected", {
          textbook_id: selectedTextbook.textbook_id,
          title: selectedTextbook.title,
          example_queries: selectedTextbook.example_queries,
        });
      }
    },
  },
  mounted() {
    this.fetchBoards();
  },
};
</script>

<style scoped>
.textbook-tree-picker {
  margin-bottom: 20px;
}
</style>
